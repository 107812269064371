<template>
  <div class="legal-services">
    <div class="container">
      <div class="banner-static-services">
        <h4>
          {{ $t('Welcome to the trademark registration service') }}
        </h4>
        <p>
          {{ $t('Welcome trademark desc') }}
        </p>
      </div>
      <Contact/>

      <div class="slider-steps">
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="title">{{ $t('Why Do You Need to Register Your Trademark?') }}</div>
            <p>
              {{ $t('Your trademark is your identity... Dont leave it unprotected') }}
            </p>
            <p class="description">
              {{ $t('common_challenges_description') }}
            </p>
            <p class="title">
              {{ $t('some_common_challenges') }}
            </p>
          </div>
          <div class="col-md-6">
            <div class="d-flex justify-content-end navigation-button ">
                            <span class="myPrev ">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect width="36" height="36" rx="5" fill="#0a3041"/>
                                    <path
                                      d="M13.6796 17.2929L17.9293 12.9999L16.9395 12L11 18L11.4949 18.4999L16.9395 24L17.9293 23.0001L13.6796 18.7071H25V17.2929H13.6796Z"
                                      fill="white"/>
                                </svg>
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect x="35.5" y="35.5" width="35" height="35" rx="4.5"
                                          transform="rotate(180 35.5 35.5)" fill="white" stroke="#AFAFAF"/>
                                    <path
                                      d="M22.3204 18.7071L18.0707 23.0001L19.0605 24L25 18L24.5051 17.5001L19.0605 12L18.0707 12.9999L22.3204 17.2929L11 17.2929L11 18.7071L22.3204 18.7071Z"
                                      fill="#AFAFAF"/>
                                </svg>
                            </span>
              <span class="myNext ">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect width="36" height="36" rx="5" fill="#0a3041"/>
                                    <path
                                      d="M13.6796 17.2929L17.9293 12.9999L16.9395 12L11 18L11.4949 18.4999L16.9395 24L17.9293 23.0001L13.6796 18.7071H25V17.2929H13.6796Z"
                                      fill="white"/>
                                </svg>
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect x="35.5" y="35.5" width="35" height="35" rx="4.5"
                                          transform="rotate(180 35.5 35.5)" fill="white" stroke="#AFAFAF"/>
                                    <path
                                      d="M22.3204 18.7071L18.0707 23.0001L19.0605 24L25 18L24.5051 17.5001L19.0605 12L18.0707 12.9999L22.3204 17.2929L11 17.2929L11 18.7071L22.3204 18.7071Z"
                                      fill="#AFAFAF"/>
                                </svg>
                            </span>
            </div>
          </div>
        </div>
        <swiper class="swiper" :breakpoints="swiperOptions.breakpoints" :space-between="20" :autoplay="{
                            delay: 3000,
                        }" :navigation="{
                            enabled: true,
                            nextEl: '.myNext',
                            prevEl: '.myPrev',
                        }" :speed="1000">
          <swiper-slide>
            <div class="item">
              <div class="index">1</div>
              <div class="title">{{ $t('risk_of_imitation_and_ip_theft_title') }}</div>
              <p>
                {{ $t('risk_of_imitation_and_ip_theft') }}
              </p>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="item">
              <div class="index">2</div>
              <div class="title">{{ $t('difficulty_in_following_registration_procedures_title') }}</div>
              <p>
                {{ $t('difficulty_in_following_registration_procedures') }}
              </p>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="item">
              <div class="index">3</div>
              <div class="title">{{ $t('need_for_effective_brand_identity_design_title') }}</div>
              <p>
                {{ $t('need_for_effective_brand_identity_design') }}
              </p>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="item">
              <div class="index">4</div>
              <div class="title">{{ $t('legal_complexities_in_disputes_and_objections_title') }}</div>
              <p>
                {{ $t('legal_complexities_in_disputes_and_objections') }}
              </p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <h5>
        {{ $t('Solution with Etmaam:') }}
      </h5>
      <p>
        {{
          $t('We offer comprehensive services that guide you through every step of trademark registration, from design to protection.')
        }}
      </p>
      <!-- CTA Button Below the Slider -->
      <div class="cta-container" style="margin-top: 40px">
        <a :href="`/services`" class="btn-main">
          {{ $t('Protect your intellectual property today') }}
        </a>
      </div>
      <div class="banner-2">
        <div class="row">
          <div class="col-md-6 mb-3">
            <img class="w-100" src="@/assets/img/services-page/trade-banner-2.png" alt="">
          </div>
          <div class="col-md-6" style="display: grid;place-content: center;">
            <div class="title">
              {{ $t('Trademark registration') }}
              <div style="font-size: 16px;">{{ $t('In the Kingdom of Saudi Arabia') }}</div>
            </div>
            <p>
              {{ $t('Trademark registration desc') }}
            </p>
          </div>
        </div>
      </div>
      <div class="how-help-you">
        <div class="title">
          {{ $t('What we offer you !') }}
        </div>
        <p class="w8">
          {{ $t('Comprehensive Trademark Registration Services: From Start to Finish') }}
        </p>
        <div class="row what-we-offer mt-5">
          <div class="col-md-3 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-7.png" alt="">
              <div class="title">{{ $t('Trademark Registration') }}</div>
              <p> {{ $t('Trademark Registration desc') }}</p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-8.png" alt="">
              <div class="title">{{ $t('Trademark Design') }}</div>
              <p>{{ $t('Trademark Design desc') }} </p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-9.png" alt="">
              <div class="title">{{ $t('Objections and Disputes') }}</div>
              <p>{{ $t('Objections and Disputes desc') }} </p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-10.png" alt="">
              <div class="title">{{ $t('Ongoing Follow-Up') }}</div>
              <p> {{ $t('Ongoing Follow-Up desc') }} </p>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Contact: defineAsyncComponent(() => import('./component/contact-services.vue')),
  },
  data () {
    return {
      swiperOptions: {
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 50
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 50
          },
          767: {
            slidesPerView: 2,
            spaceBetween: 50
          },
          991: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 20
          },
        }
      },
    }
  },
  methods: {},
  mounted () {
  }

}
</script>
